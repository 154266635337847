import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ImageDrop } from 'quill-image-drop-module'

import axios from 'axios'
import { API_URL } from '../constants/Datas'
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false
Quill.register('modules/imageDrop', ImageDrop, true)

// Quill.register('modules/clipboard', PlainClipboard, true);

const QuillClipboard = Quill.import('modules/clipboard')

class Clipboard extends QuillClipboard {
  getMetaTagElements = (stringContent) => {
    const el = document.createElement('div')
    el.innerHTML = stringContent
    return el.getElementsByTagName('meta')
  }

  async onPaste(e) {
    let clipboardData = e.clipboardData || window.clipboardData
    let pastedData = await clipboardData.getData('Text')
    console.log(e.clipboardData)
    const urlMatches = pastedData.match(/\b(http)?:\/\/\S+/gi) || []
    if (urlMatches.length > 0) {
      // e.preventDefault()
      urlMatches.forEach((link) => {
        axios
          .get(link)
          .then((payload) => {
            // let title, image, url, description;
            let title, image, url
            for (let node of this.getMetaTagElements(payload)) {
              if (node.getAttribute('property') === 'og:title') {
                title = node.getAttribute('content')
              }
              if (node.getAttribute('property') === 'og:image') {
                image = node.getAttribute('content')
              }
              if (node.getAttribute('property') === 'og:url') {
                url = node.getAttribute('content')
              }
              // if (node.getAttribute("property") === "og:description") {
              //     description = node.getAttribute("content");
              // }
            }

            const rendered = `<a href=${url} target="_blank"><div><img src=${image} alt=${title} width="20%"/><span>${title}</span></div></a>`

            let range = this.quill.getSelection()
            let position = range ? range.index : 0
            this.quill.pasteHTML(position, rendered, 'silent')
            this.quill.setSelection(position + rendered.length)
          })
          .catch((error) => console.error(error))
      })
    } else {
      //console.log('when to use this') 보통 다른 곳에서  paste 한다음에  copy하면 이쪽 걸로 한다.
      super.onPaste(e)
    }
  }
}
Quill.register('modules/clipboard', Clipboard, true)

const BlockEmbed = Quill.import('blots/block/embed')

class ImageBlot extends BlockEmbed {
  static create(value) {
    const imgTag = super.create()
    imgTag.setAttribute('src', value.src)
    imgTag.setAttribute('alt', value.alt)
    imgTag.setAttribute('width', '100%')
    return imgTag
  }

  static value(node) {
    return { src: node.getAttribute('src'), alt: node.getAttribute('alt') }
  }
}

ImageBlot.blotName = 'image'
ImageBlot.tagName = 'img'
Quill.register(ImageBlot)

class VideoBlot extends BlockEmbed {
  static create(value) {
    if (value && value.src) {
      const videoTag = super.create()
      videoTag.setAttribute('src', value.src)
      videoTag.setAttribute('title', value.title)
      videoTag.setAttribute('width', '100%')
      videoTag.setAttribute('controls', '')

      return videoTag
    } else {
      const iframeTag = document.createElement('iframe')
      iframeTag.setAttribute('src', value)
      iframeTag.setAttribute('frameborder', '0')
      iframeTag.setAttribute('allowfullscreen', true)
      iframeTag.setAttribute('width', '100%')
      return iframeTag
    }
  }

  static value(node) {
    if (node.getAttribute('title')) {
      return { src: node.getAttribute('src'), alt: node.getAttribute('title') }
    } else {
      return node.getAttribute('src')
    }
    // return { src: node.getAttribute('src'), alt: node.getAttribute('title') };
  }
}

VideoBlot.blotName = 'video'
VideoBlot.tagName = 'video'
Quill.register(VideoBlot)

class FileBlot extends BlockEmbed {
  static create(value) {
    const prefixTag = document.createElement('span')
    prefixTag.innerText = '첨부파일 - '

    const bTag = document.createElement('b')
    //위에 첨부파일 글자 옆에  파일 이름이 b 태그를 사용해서 나온다.
    bTag.innerText = value

    const linkTag = document.createElement('a')
    linkTag.setAttribute('href', value)
    linkTag.setAttribute('target', '_blank')
    linkTag.setAttribute('className', 'file-link-inner-post')
    linkTag.appendChild(bTag)
    //linkTag 이런식으로 나온다 <a href="btn_editPic@3x.png" target="_blank" classname="file-link-inner-post"><b>btn_editPic@3x.png</b></a>

    const node = super.create()
    node.appendChild(prefixTag)
    node.appendChild(linkTag)

    return node
  }

  static value(node) {
    const linkTag = node.querySelector('a')
    return linkTag.getAttribute('href')
  }
}

FileBlot.blotName = 'file'
FileBlot.tagName = 'p'
FileBlot.className = 'file-inner-post'
Quill.register(FileBlot)

class PollBlot extends BlockEmbed {
  static create(value) {
    const prefixTag = document.createElement('span')
    prefixTag.innerText = '투표 - '

    const bTag = document.createElement('b')
    bTag.innerText = value.title

    const node = super.create()
    node.setAttribute('id', value.id)
    node.appendChild(prefixTag)
    node.appendChild(bTag)

    return node
  }

  static value(node) {
    const id = node.getAttribute('id')
    const bTag = node.querySelector('b')
    const title = bTag.innerText
    return { id, title }
  }
}

PollBlot.blotName = 'poll'
PollBlot.tagName = 'p'
PollBlot.className = 'poll-inner-post'
Quill.register(PollBlot)

class QuillEditor extends React.Component {
  bandId
  placeholder
  onEditorChange
  onFilesChange
  onPollsChange
  _isMounted

  constructor(props) {
    super(props)

    this.state = {
      editorHtml: __ISMSIE__ ? '<p>&nbsp;</p>' : '',
      files: [],
    }

    this.reactQuillRef = null

    this.inputOpenImageRef = React.createRef()
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleChange = (html) => {
    // https://youtu.be/BbR-QCoKngE
    // https://www.youtube.com/embed/ZwKhufmMxko
    // https://tv.naver.com/v/9176888
    // renderToStaticMarkup(ReactHtmlParser(html, options));

    this.setState(
      {
        editorHtml: html,
      },
      () => {
        this.props.onEditorChange(this.state.editorHtml)
      }
    )
  }

  // I V F P들을  눌렀을떄 insertImage: this.imageHandler로 가서  거기서 inputOpenImageRef를 클릭 시킨다.
  imageHandler = () => {
    this.inputOpenImageRef.current.click()
  }

  // insertImage = (e) => {
  //   e.stopPropagation()
  //   e.preventDefault()

  //   if (
  //     e.currentTarget &&
  //     e.currentTarget.files &&
  //     e.currentTarget.files.length > 0
  //   ) {
  //     const file = e.currentTarget.files[0]

  //     const URL_IMG = URL.createObjectURL(file)

  //     let formData = new FormData()
  //     const config = {
  //       header: { 'content-type': 'multipart/form-data' },
  //     }
  //     formData.append('file', file)

  //     const quill = this.reactQuillRef.getEditor()
  //     quill.focus()

  //     let range = quill.getSelection()
  //     let position = range ? range.index : 0

  //     //먼저 노드 서버에다가 이미지를 넣은 다음에   여기 아래에 src에다가 그걸 넣으면 그게
  //     //이미지 블롯으로 가서  크리에이트가 이미지를 형성 하며 그걸 발류에서     src 랑 alt 를 가져간후에  editorHTML에 다가 넣는다.
  //     quill.insertEmbed(position, 'image', {
  //       src: URL_IMG,
  //       alt: file.name,
  //     })
  //     quill.setSelection(position + 1)

  //     if (this._isMounted) {
  //       this.setState(
  //         {
  //           files: [...this.state.files, file],
  //         },
  //         () => {
  //           this.props.onFilesChange(this.state.files)
  //         }
  //       )
  //     }
  //   }
  // }

  insertImage = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0]

      let formData = new FormData()
      const config = {
        header: { 'content-type': 'multipart/form-data' },
      }
      formData.append('images', file)

      axios.post('/api/v1/upload', formData, config).then((response) => {
        if (response.data.files) {
          const quill = this.reactQuillRef.getEditor()
          quill.focus()
          console.log(response.data.files)

          let range = quill.getSelection()
          let position = range ? range.index : 0

          //먼저 노드 서버에다가 이미지를 넣은 다음에   여기 아래에 src에다가 그걸 넣으면 그게
          //이미지 블롯으로 가서  크리에이트가 이미지를 형성 하며 그걸 발류에서     src 랑 alt 를 가져간후에  editorHTML에 다가 넣는다.
          quill.insertEmbed(position, 'image', {
            src: API_URL + response.data.files,
            alt: 'Document Image',
          })
          quill.setSelection(position + 1)

          if (this._isMounted) {
            this.setState(
              {
                files: [...this.state.files, file],
              },
              () => {
                this.props.onFilesChange(this.state.files)
              }
            )
          }
        } else {
          return alert('failed to upload file')
        }
      })
    }
  }
  render() {
    return (
      <div>
        <div id='toolbar'>
          <select
            className='ql-header'
            defaultValue={''}
            onChange={(e) => e.persist()}
          >
            <option value='1' />
            <option value='2' />
            <option value='3' />
            <option value='4' />
            <option value='' />
          </select>
          <select class='ql-size'>
            <option value='small'></option>
            <option selected></option>
            <option value='large'></option>
            <option value='huge'></option>
          </select>
          <button className='ql-bold' />
          <button className='ql-italic' />
          <button className='ql-underline' />
          <button className='ql-strike' />
          <button className='ql-insertImage'>
            <i className='far fa-image' style={{ color: 'black' }}></i>
          </button>

          <button className='ql-link' />
          <button className='ql-code-block' />
          <button className='ql-video' />
          <button className='ql-blockquote' />
          <button className='ql-clean' />
          <button className='ql-align' />

          <button className='ql-align' value='center' />
          <button className='ql-align' value='right' />
          <button className='ql-list' value='ordered' />
          <button className='ql-list' value='bullet' />
          <span className='ql-formats'>
            <select className='ql-color' />
            <select className='ql-background' />
          </span>

          {/* <button className='ql-list' /> */}
        </div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el
          }}
          theme={'snow'}
          onChange={this.handleChange}
          modules={this.modules}
          formats={this.formats}
          value={this.state.editorHtml}
          placeholder={this.props.placeholder}
        />
        <input
          type='file'
          accept='image/*'
          ref={this.inputOpenImageRef}
          style={{ display: 'none' }}
          onChange={this.insertImage}
        />
      </div>
    )
  }

  modules = {
    syntax: false,
    toolbar: {
      container: '#toolbar',
      'image-tooltip': true,
      handlers: {
        insertImage: this.imageHandler,
        insertPoll: this.pollHandler,
      },

      imageDrop: true,
    },
  }

  formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'image',
    'link',
    'code-block',
    'video',
    'blockquote',
    'clean',
    'align',
    'list',
    'color',
  ]
}

export default QuillEditor
