import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
} from '../components/utils/Validation'
import axios from 'axios'
import { LocationContext } from '../LocationAuthProvider'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const context = useContext(LocationContext)

  const [loading, setLoading] = useState(false)
  const { user, setuser } = context

  //   const dispatch = useDispatch()
  //   const userLogin = useSelector((state) => state.user)

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (user) {
      history.push(redirect)
    }
  }, [history, user, redirect])
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const submitHandler = async (e) => {
    e.preventDefault()
    if (isEmpty(password) || isEmpty(email))
      return setError('Please fill in all the fields.')

    if (!isEmail(email)) return setError('Invalid email format.')
    if (isLength(password))
      return setError('Password must be at least 6 characters.')
    setLoading(true)
    try {
      const { data } = await axios.post(
        '/api/v1/user/login',
        { email, password },
        config
      )

      setuser(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <FormContainer>
      <h1>Sign In </h1>
      {error && <Message variant='danger'> {error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label className='formlabel'>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='password'>
          <Form.Label className='formlabel'>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            minLength={6}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <div className='text-button-forgot py-1'>
          <Link className='text-link' to={'/forgotpassword'}>
            <div className='Text'> Forgot Password? </div>
          </Link>
        </div>
        <Button type='submit' variant='primary' className='button'>
          SIgn In
        </Button>
      </Form>
      <div className='text-button py-3'>
        New to Mushroom Map?
        <Link className='text-link' to={'/register'}>
          <div className='Text'> Register </div>
        </Link>
      </div>
    </FormContainer>
  )
}

export default LoginScreen
