import React from 'react'
import { MUSHROOM_SIZE } from '../constants/Sizes'

const LocationMarker = ({ lat, lng, type, onClick }) => {
  const renderIco = () => {
    if (type.length > 1) {
      return (
        <img alt='mushroom icon' src={'mixed.png'} height={50} width={50} />
      )
    } else {
      switch (type[0].value) {
        case 'amanita':
          return (
            <img
              src={'Amanita.png'}
              height={MUSHROOM_SIZE}
              alt='mushroom icon'
              width={MUSHROOM_SIZE}
            />
          )
        case 'morel':
          return (
            <img
              alt='mushroom icon'
              src={'morel.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'bolete':
          return (
            <img
              alt='mushroom icon'
              src={'bolete.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'armillara':
          return (
            <img
              alt='mushroom icon'
              src={'armillara.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'chanterelle':
          return (
            <img
              alt='mushroom icon'
              src={'chanterelle.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'coral':
          return (
            <img
              alt='mushroom icon'
              src={'Coral.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'honeyfungus':
          return (
            <img
              alt='mushroom icon'
              src={'HoneyFungus.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'matsutake':
          return (
            <img
              alt='mushroom icon'
              src={'matsutake.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'oyster':
          return (
            <img
              alt='mushroom icon'
              src={'oyster.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'parasol':
          return (
            <img
              alt='mushroom icon'
              src={'parasol.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'psilocybin':
          return (
            <img
              alt='mushroom icon'
              src={'psilocybin.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'stinkhorn':
          return (
            <img
              alt='mushroom icon'
              src={'stinkhorn.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'lionsMane':
          return (
            <img
              alt='mushroom icon'
              src={'LionsMane.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'maitake':
          return (
            <img
              alt='mushroom icon'
              src={'Maitake.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'agaricus':
          return (
            <img
              alt='mushroom icon'
              src={'Agaricus.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'turkeytail':
          return (
            <img
              alt='mushroom icon'
              src={'turkeytail.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'deathcapmushroom':
          return (
            <img
              alt='mushroom icon'
              src={'DeathCapMushroom.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'chickenofthewoods':
          return (
            <img
              alt='mushroom icon'
              src={'ChickenOfTheWoods.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'hydnellumpeckii':
          return (
            <img
              alt='mushroom icon'
              src={'HydnellumPeckii.png'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'reishi':
          return (
            <img
              alt='mushroom icon'
              src={'Reishi.png'}
              height={50}
              width={50}
            />
          )
        default:
          return (
            <img alt='mushroom icon' src={'mixed.png'} height={50} width={50} />
          )
      }
    }
  }

  return (
    <div className='location-marker' onClick={onClick} type={type}>
      {renderIco()}
    </div>
  )
}

export default LocationMarker
