import React, { useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'

import LocationAuthProvider, { LocationContext } from './LocationAuthProvider'
import PostScreen from './screens/PostScreen'
import PlaceDetails from './screens/PlaceDetailsScreen'
import UserProfileScreen from './screens/UserProfileScreen'
import RegisterScreen from './screens/RegisterScreen'
import EditProfileScreen from './screens/EditProfileScreen'
import PrivateRoute from './PrivateRoute'
import EditPostScreen from './screens/EditPostScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import ContactScreen from './screens/ContactScreen'
import PrivatePostScreen from './screens/PrivatePostScreen'
import ForgotPassEmailScreen from './screens/ForgotPassEmailScreen'
import ChangePasswordScreen from './screens/ChangePasswordScreen'
import UserListScreen from './screens/UserListScreen'
import AdminRoute from './AdminRoute'
import CreateBlogPostScreen from './screens/CreateBlogPostScreen'
import UpdateUserAdminScreen from './screens/UpdateUserAdminScreen'
import BlogsListScreen from './screens/BlogsListScreen'
import BlogContentScreen from './screens/BlogContentScreen'

function App() {
  return (
    <LocationAuthProvider>
      <Router>
        <Header />

        <Route path='/' component={HomeScreen} exact />
        <Route path='/login' component={LoginScreen} exact />

        <Route path='/register' component={RegisterScreen} exact />
        <Route path='/addsighting' component={PostScreen} exact />
        <Route path='/place/:id' component={PlaceDetails} exact />
        <Route path='/forgotpassword' component={ForgotPassEmailScreen} exact />
        <Route
          path='/resetpassword/:id?'
          component={ChangePasswordScreen}
          exact
        />

        <PrivateRoute path='/profile/:id' component={UserProfileScreen} exact />
        <PrivateRoute path='/editprofile' component={EditProfileScreen} exact />
        <PrivateRoute path='/editpost/:id' component={EditPostScreen} exact />
        <PrivateRoute
          path='/privateposts'
          component={PrivatePostScreen}
          exact
        />

        <Route path='/aboutus' component={AboutUsScreen} exact />
        <Route path='/contactus' component={ContactScreen} exact />
        <AdminRoute path='/admin/userlist' component={UserListScreen} exact />
        <AdminRoute
          path='/admin/createblogpost'
          component={CreateBlogPostScreen}
          exact
        />
        <AdminRoute
          path='/admin/user/:id/edit'
          component={UpdateUserAdminScreen}
          exact
        />

        <Route path='/blogs' component={BlogsListScreen} exact />
        <Route path='/blog/:id' component={BlogContentScreen} exact />
      </Router>
    </LocationAuthProvider>
  )
}

export default App
