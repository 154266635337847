import React, { useContext, useState, useEffect, useRef } from 'react'
import {
  Container,
  Form,
  FormControl,
  Button,
  ListGroup,
  Row,
  Col,
  Image,
  Alert,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultiSelect from '../components/MultiSelect'
import PlaceAutocomplete from '../components/PlaceAutocomplete'
import DangerToast from '../components/toasts/DangerToast'
import { IMG_SIZE, MAX_DETAILS_SIZE } from '../constants/Sizes'
import {
  COUNT_VALIDATION,
  INPUT_VALIDATION,
  PLACE_VALIDATION,
  SIZE_VALIDATION,
} from '../constants/ToastTexts'
import { LocationContext } from '../LocationAuthProvider'
import axios from 'axios'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SuccessToast from '../components/toasts/SuccessToast'
import AuthLoader from '../components/AuthLoader'
import { Link } from 'react-router-dom'
import { API_URL } from '../constants/Datas'

const EditPostScreen = ({ history, match }) => {
  const context = useContext(LocationContext)
  const fileRef = useRef(null)
  const [privateSwitch, setprivateSwitch] = useState(false)

  const [sightingDate, setSightingDate] = useState(new Date())
  const [mushroomType, SetmushroomType] = useState([])
  const [images, setImages] = useState([])
  const [toast, setToast] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [details, setDetails] = useState('')
  const [likes, setLikes] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadedImage, setUploadedImage] = useState([])
  const { coordinates, user, authLoader, setCoordinates } = context
  const [formatadress, setFormatAdress] = useState('')
  const [deletedImg, setdeletedImg] = useState([])
  const [title, setTitle] = useState('')
  const imageHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i]
      newImage['id'] = Math.floor(Math.random() * 100)
      newImage['relativePath'] = URL.createObjectURL(newImage)
      if (newImage.size < IMG_SIZE) {
        const concatted = images.concat(uploadedImage)
        if (concatted.length < 10) {
          setImages((prevImages) => [...prevImages, newImage])
        } else {
          setShow(true)
          setToast(COUNT_VALIDATION)
        }
      } else {
        setShow(true)
        setToast(SIZE_VALIDATION)
      }
    }
  }
  const removeFromLartHandler = (id) => {
    const imagess = images.filter((img) => img.id !== id)

    setImages(imagess)
  }
  const removeImageFromFile = (id) => {
    const imagess = uploadedImage.filter((img) => img !== id)
    setUploadedImage(imagess)
    setdeletedImg((prevImages) => [...prevImages, id])
  }

  const createMainPost = async (files) => {
    setLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      await axios
        .put(
          `/api/v1/places/${match.params.id}`,
          {
            files,
            mushroomType,
            coordinates,
            details,
            likes,
            title,
            sightingDate,
            deletedImg,
            uploadedImage,
            privateSwitch,
          },
          config
        )
        .then(function (response) {
          setVicToast(response.data.message)
          setVicShow(true)
          setLoading(false)
          setImages([])

          history.push(`/place/${match.params.id}`)
          seterrorMessage('')
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const createPost = async () => {
    setLoading(true)
    const formData = new FormData()
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i])
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .post('/api/v1/upload', formData, config)
        .then(function (response) {
          createMainPost(response.data.files)
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const submitHandle = () => {
    if (coordinates.formattedAddress) {
      if (mushroomType.length > 0 && sightingDate && title) {
        if (images.length > 0) {
          createPost()
        } else {
          createMainPost('')
        }
      } else {
        setShow(true)
        setToast(INPUT_VALIDATION)
      }
    } else {
      setShow(true)
      setToast(PLACE_VALIDATION)
    }
  }
  // :  errorMessage ? (
  //   <Message variant='danger'>{error}</Message>
  // ) :

  const fetchPlace = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`/api/v1/places/${match.params.id}`)
      if (data) {
        if (user) {
          if (user._id == data.data.user) {
            setDetails(data.data.details)
            setTitle(data.data.title)
            setSightingDate(new Date(data.data.sightingDate))
            setFormatAdress(data.data.location.formattedAddress)
            SetmushroomType(data.data.mushroomType)
            if (data.data.files) {
              var imgs = data.data.files.split(',')
              setUploadedImage(imgs)
            }
            setCoordinates({
              lat: data.data.location.coordinates[1],
              lng: data.data.location.coordinates[0],
              formattedAddress: data.data.location.formattedAddress,
            })
            setprivateSwitch(data.data.private)
            setLoading(true)
          } else {
            history.push('/')
          }
        }
      }
      setLoading(false)
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPlace()
  }, [history, user])

  return (
    <div className='main-flow'>
      {loading ? (
        <Loader />
      ) : (
        <Container className='post-container'>
          {/* {authLoader ? (
            <AuthLoader />
          ) : user ? null : (
            <Alert
              variant='warning'
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              Please
              <Link className='text-link mx-1' to={'/login'}>
                <div className='Text'> Login </div>
              </Link>
              to create post{' '}
            </Alert>
          )} */}
          {errorMessage ? (
            <Message variant='danger'>{errorMessage}</Message>
          ) : (
            <div>
              <h1>Add a mushroom sighting </h1>
              <label className='labelname'>Location</label>

              {formatadress ? (
                <PlaceAutocomplete
                  buttonVisible={false}
                  inputWidth={'25rem'}
                  formatadress={formatadress}
                />
              ) : (
                <AuthLoader />
              )}
              <Form.Group controlId='title'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  value={title}
                  type='text'
                  placeholder='Post Title'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <label className='labelname'>Date</label>
              <DatePicker
                className='form-control'
                selected={sightingDate}
                onChange={(date) => setSightingDate(date)}
                dateFormat='MMM dd, yyyy'
                //   placeholderText={toString(selectedDate)}
                isClearable
                clearButtonTitle='clear'
                required
              />
              <label className='labelname'>Select Mushroom types</label>

              <MultiSelect
                SetmushroomType={SetmushroomType}
                mushroomType={mushroomType}
              />

              <Form.Group>
                <Form.Label>Upload images (Optional)</Form.Label>
                <Form.Control
                  id='image-file'
                  itemType='image'
                  type='file'
                  placeholder='select Image'
                  accept='image/*'
                  className='input-file'
                  multiple
                  ref={fileRef}
                  // style={{ color: 'transparent', width: '70px' }}
                  onChange={imageHandler}
                  onClick={() => {
                    fileRef.current.value = null
                  }}
                />
              </Form.Group>
              <ListGroup variant='flush' className='listcontainer'>
                {images.map((item) => (
                  <ListGroup.Item key={item.id} className='listgroup'>
                    <Row>
                      <Col md={3}>
                        <Image
                          src={item.relativePath}
                          alt={item.name}
                          fluid
                          rounded
                          height='30'
                          width='50'
                        />
                      </Col>
                      <Col className='imagename' md={4}>
                        {item.name}
                      </Col>
                      <Col className='deletecontainer'>
                        <Button
                          onClick={() => {
                            removeFromLartHandler(item.id)
                          }}
                          type='button'
                          variant='light'
                        >
                          <i className='fas fa-trash fa-lg'></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}

                {uploadedImage.map((item) => (
                  <ListGroup.Item key={item.name} className='listgroup'>
                    <Row>
                      <Col md={3}>
                        <Image
                          src={API_URL + item}
                          alt='Mushroom Image'
                          fluid
                          rounded
                          height='30'
                          width='50'
                        />
                      </Col>
                      <Col className='imagename' md={4}>
                        {item}
                      </Col>
                      <Col className='deletecontainer'>
                        <Button
                          onClick={() => {
                            removeImageFromFile(item)
                          }}
                          type='button'
                          variant='light'
                        >
                          <i className='fas fa-trash fa-lg'></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <label className='labelname'>Details</label>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  id='details'
                  rows='7'
                  value={details}
                  maxLength={MAX_DETAILS_SIZE}
                  aria-label='Place Details'
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
              <Form.Group>
                <div className='private-switch'>
                  <Form.Label>Private Mode </Form.Label>

                  <Form.Check
                    type='checkbox'
                    id='customswitch'
                    checked={privateSwitch}
                    className='my-3 mx-2'
                    onChange={(e) => setprivateSwitch(e.currentTarget.checked)}
                  />
                </div>
              </Form.Group>
              <Button
                variant='primary'
                type='submit'
                className='post-button'
                disabled={!user}
                onClick={() => {
                  submitHandle()
                  // console.log(category)
                }}
              >
                Save Changes
              </Button>
            </div>
          )}
        </Container>
      )}

      <DangerToast show={show} setShow={setShow} text={toast} />
      <SuccessToast vicshow={vicshow} setVicShow={setVicShow} text={victoast} />
    </div>
  )
}

export default EditPostScreen
