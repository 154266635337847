import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col, ListGroup, Image } from 'react-bootstrap'
import BlogCard from '../components/cards/BlogCard'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import imgSD from '../avatar/usage.jpeg'
import { API_URL } from '../constants/Datas'
const BlogsListScreen = () => {
  const [blogs, setblogs] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const getblogs = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`/api/v1/admin/getblogs`)
      setblogs(data.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  useEffect(() => {
    getblogs()
  }, [])

  const extractPara = (params) => {
    const paragraphs = params.split('</p>')
    const firstParagraph = paragraphs[0]
    const paragraphContent = firstParagraph.replace('<p>', '')
    return paragraphContent
  }

  return (
    <Container className='blog-post-container'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <ListGroup variant='flush'>
            {blogs.map((item) => (
              <ListGroup.Item key={item._id}>
                <Link className='text-link' to={`/blog/${item._id}`}>
                  <Row className='my-4'>
                    <Col md={5}>
                      <Image
                        className='blogImage'
                        src={API_URL + item.coverImg}
                        alt={item.title}
                        fluid
                      />
                    </Col>
                    <Col className='imagename' md={7}>
                      <h4> {item.title}</h4>
                      <p className='next-col '>
                        {' '}
                        <i className='far fa-folder'></i>{' '}
                        {item.blogType[0].label}
                      </p>

                      <p className='text-des'>{item.desc}</p>

                      <Button
                        size='large'
                        type='submit'
                        variant='primary'
                        className='button'
                        // onSubmit={onSubmit}
                      >
                        Continue Reading
                      </Button>
                    </Col>
                  </Row>
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )}
    </Container>
  )
}

export default BlogsListScreen
