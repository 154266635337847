import React, { useState } from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'
const DangerToast = (props) => {
  const { show, setShow, text } = props

  const onClose = () => {
    setShow(false)
  }
  return (
    <ToastContainer className='p-3' position='top-end'>
      <Toast show={show} onClose={onClose} bg='danger'>
        <Toast.Header closeButton={true}>
          <strong className='me-auto'>Something went wrong!</strong>
        </Toast.Header>
        <Toast.Body className={'text-white'}>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default DangerToast
