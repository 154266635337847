import React, { useState, useEffect } from 'react'
import axios from 'axios'
export const LocationContext = React.createContext({})
const LocationAuthProvider = ({ children }) => {
  const [user, setuser] = useState(null)
  const [authLoader, setAuthLoader] = useState(false)
  const userLocation = localStorage.getItem('currentlocation')
    ? JSON.parse(localStorage.getItem('currentlocation'))
    : null
  const [coordinates, setCoordinates] = useState({
    lat: userLocation ? userLocation.lat : 47.32,
    lng: userLocation ? userLocation.lng : -120.740135,
    formattedAddress: '',
  })
  const [postMarker, setpostMarker] = useState([])
  const [privatePosts, setprivatePosts] = useState([])
  const [dropDownText, setdropDownText] = useState('All Post')

  const refs = postMarker.reduce((acc, value) => {
    acc[value._id] = React.createRef()
    return acc
  }, {})
  const privateRefs = privatePosts.reduce((acc, value) => {
    acc[value._id] = React.createRef()
    return acc
  }, {})

  const getLoggedIn = async () => {
    try {
      setAuthLoader(true)
      const loggedIn = await axios.get('/api/v1/user/loggedin')

      if (loggedIn.data) {
        setuser(loggedIn.data)
      } else {
        setuser(loggedIn.data)
      }
      setAuthLoader(false)
    } catch (error) {
      setAuthLoader(false)
    }
  }

  useEffect(() => {
    getLoggedIn()
  }, [])

  return (
    <LocationContext.Provider
      value={{
        coordinates,
        setCoordinates,
        postMarker,
        setpostMarker,
        refs,
        user,
        authLoader,
        setprivatePosts,
        privatePosts,
        setuser,
        setdropDownText,
        dropDownText,
        privateRefs,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export default LocationAuthProvider
