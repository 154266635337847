import Message from '../components/Message'
import Loader from '../components/Loader'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import axios from 'axios'

const UpdateUserAdminScreen = ({ match, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setloading] = useState(false)
  const [error, setError] = useState('')
  const [loadingUpdate, setloadingUpdate] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState('')
  const getuserbyID = async () => {
    try {
      setloading(true)
      const { data } = await axios.get(
        `/api/v1/user/getuser/${match.params.id}`
      )
      setEmail(data.email)
      setName(data.name)
      setIsAdmin(data.isAdmin)

      setloading(false)
    } catch (error) {
      setloading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      setloadingUpdate(true)
      const { data } = await axios.put(
        `/api/v1/admin/handleuser/${match.params.id}`,
        { isAdmin },
        config
      )
      if (data) {
        setIsAdmin(data.isAdmin)
      }
      setloadingUpdate(false)
    } catch (error) {
      setloadingUpdate(false)
      setErrorUpdate(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  useEffect(() => {
    getuserbyID()
  }, [match, history])

  return (
    <>
      <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                disabled
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                disabled
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='isadmin'>
              <Form.Check
                className='chk'
                type='checkbox'
                label='Is Admin'
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default UpdateUserAdminScreen
