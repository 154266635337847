import React from 'react'
import { Container, Image } from 'react-bootstrap'

const AboutUsScreen = () => {
  return (
    <Container className='about-container my-4 '>
      {/* <figure>
        <blockquote class='blockquote'>
          <p class='mb-0'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            posuere erat a ante.
          </p>
        </blockquote>
        <figcaption class='blockquote-footer'>
          Someone famous in <cite title='Source Title'>Source Title</cite>
        </figcaption>
      </figure> */}

      <Image src='merly1.jpg' fluid className='img-about' />

      <h3>MY Mushroom Story</h3>
      <div className='text-about '>
        <p className='para'>
          One day, I found an interesting-looking mushroom growing in one of my
          garden pots. Curious as to what it was, I joined a mushroom group on
          Facebook. I then learned that the mushroom that I found was a morel
          mushroom! Since then, I was hooked! I started growing mushrooms and
          then I joined a mycology group in Seattle.
        </p>

        <p className='para'>
          I spent a lot of weekends going to the forests to hunt for mushrooms.
          Eventually, I learned so many things such as where to find them, what
          trees have symbiotic relationships with certain species of mushrooms,
          effect of temperature and elevation to mushroom growth and so much
          more. I was really good at finding mushrooms! I volunteered as a
          mushroom hunter guide to the mycology group I was a part of. It was a
          lot of fun teaching the members how and where to find mushrooms. I
          learned that some people are afraid to pick mushrooms because they are
          worried that they’d pick the poisonous ones. What was nice was that
          there’s so many knowledgeable people who were willing to share their
          information about mushrooms! And then I also met a lot of people who
          enjoyed sharing their mushroom finds!
        </p>
        <p className='para'>
          So, together with my family and friends, we built this platform for
          everyone to show the mushrooms that you find. Don't worry, you don't
          have to share the specific location for your mushroom finds unless you
          want to. You can use this to show-off your finds or to help people
          find mushrooms too! It's encouraged to give some information as to
          what trees, elevation, and so on for where you found the mushroom, but
          it's not required to do that. If you're not sure what mushroom you
          found, just put “unknown”.
        </p>
        <p className='para'>
          My name is Merly and I'm a mushroom hunter with the passion of
          teaching people how and where to hunt for mushrooms. I hope that you
          will find this platform helpful.
        </p>
      </div>
    </Container>
  )
}

export default AboutUsScreen
