import React, { useState, useContext, useEffect } from 'react'
import Avatar from 'react-avatar'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { API_URL, AVATAR_URL } from '../constants/Datas'
import { LocationContext } from '../LocationAuthProvider'
import axios from 'axios'
import AuthLoader from '../components/AuthLoader'
import MushroomCard from '../components/cards/MushroomCard'
import Message from '../components/Message'
import Footer from '../components/Footer'
const UserProfileScreen = ({ history, match }) => {
  const context = useContext(LocationContext)
  const [loading, setLoading] = useState(false)
  const { user, authLoader } = context
  const [postCreator, setpostCreator] = useState(null)
  const [userload, setuserLoad] = useState(false)
  const [userPost, setUserPost] = useState([])
  const [error, setError] = useState('')
  const getuserbyID = async () => {
    try {
      setuserLoad(true)
      const { data } = await axios.get(
        `/api/v1/user/getuser/${match.params.id}`
      )
      setpostCreator(data)
      setuserLoad(false)
    } catch (error) {
      setuserLoad(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  const getuserPost = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `/api/v1/places/userposts/${match.params.id}`
      )
      setUserPost(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  useEffect(() => {
    getuserbyID()
    getuserPost()
  }, [match, history])

  return (
    <div className='main-flow'>
      {userload ? (
        <Loader />
      ) : postCreator ? (
        <Container className='post-container'>
          <Row>
            <Col
              md={3}
              className='profile-avatar'
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <Avatar
                src={
                  postCreator.image ? API_URL + postCreator.image : AVATAR_URL
                }
                size={140}
                className='sb-avatar'
                style={{ margin: 10 }}
              />
            </Col>
            <Col
              style={{
                justifyContent: 'flex-start',
                display: 'flex',
              }}
            >
              <Row className='py-4'>
                <Col className='h3'>{postCreator.name}</Col>

                <Row>
                  <Col md={5} className='h4'>
                    {userPost.length}
                  </Col>
                </Row>
                <Row>
                  <Col md={5} className='h4'>
                    Posts
                  </Col>
                </Row>
              </Row>
            </Col>
            <Row
              style={{
                display: 'flex',
              }}
            >
              <Col md={5} style={{ textAlign: 'justify' }}></Col>
            </Row>
            <Row className='my-2 mx-2'> {postCreator.bio}</Row>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {authLoader ? (
                <AuthLoader />
              ) : user._id === postCreator._id ? (
                <Row>
                  <Col md={4}>
                    <Link to='/editprofile'>
                      <Button
                        type='submit'
                        variant='primary'
                        className='button'
                      >
                        Edit Profile
                      </Button>
                    </Link>
                  </Col>
                  <Col md={4}>
                    <Link to='/privateposts'>
                      <Button
                        type='submit'
                        variant='primary'
                        className='button'
                      >
                        Private Posts
                      </Button>
                    </Link>
                  </Col>
                </Row>
              ) : null}
            </Row>
          </Row>
          <Row
            className='g-4 my-3'
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : userPost.length == 0 ? (
              <Container className='footer-position'>
                <h1> NO post by user.</h1>
              </Container>
            ) : (
              userPost.map((post) => (
                <Col sm={6} md={4}>
                  {/* <Button onClick={() => handleClick(post._id)} /> */}

                  <MushroomCard key={post._id} post={post} />
                </Col>
              ))
            )}
            <div className='my-3'></div>
          </Row>{' '}
        </Container>
      ) : null}
    </div>
  )
}

export default UserProfileScreen
