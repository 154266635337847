import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ContactScreen = () => {
  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : ''
    if (subject) params += `subject=${encodeURIComponent(subject)}`

    return (
      <a className='contact-text' href={`mailto:${email}${params}`}>
        {children}
      </a>
    )
  }
  return (
    <div className='main-flow'>
      <Container>
        <div className='m-auto my-4' style={{ width: '60%' }}>
          <h3>Contact</h3>
          <div className='para-contact'>
            {' '}
            We are a small business and we value customer’s feedback and
            suggestions. Feel free to{' '}
            <Mailto
              email='gomushroomhunting@gmail.com'
              subject='Comment or Suggestion for Gomushroomhunting'
            >
              gomushroomhunting@gmail.com{' '}
            </Mailto>{' '}
            at for questions, comments, or suggestions.{' '}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContactScreen
