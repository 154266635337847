import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Message from '../components/Message'
import Loader from '../components/Loader'
import MushroomCard from '../components/cards/MushroomCard'
import { Col, Container, Row } from 'react-bootstrap'

const PrivatePostScreen = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [privatePosts, setprivatePosts] = useState([])
  const fetchEvents = async () => {
    try {
      setLoading(true)
      await axios.get('api/v1/places/privatepost').then(function (response) {
        setprivatePosts(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <div className='main-flow'>
      <Container className='private-container' fluid>
        <h3>Private Posts</h3>
        <Row className='g-4 my-3'>
          {loading ? (
            <Loader />
          ) : errorMessage ? (
            <Message variant='danger'>{errorMessage}</Message>
          ) : privatePosts.length == 0 ? (
            <Container className='footer-position'>
              <h1> NO private post by you</h1>
            </Container>
          ) : (
            privatePosts.map((post) => (
              <Col sm={6} md={4}>
                {/* <Button onClick={() => handleClick(post._id)} /> */}

                <MushroomCard key={post._id} post={post} />
              </Col>
            ))
          )}
        </Row>{' '}
      </Container>
    </div>
  )
}

export default PrivatePostScreen
