import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const FormContainer = ({ children }) => {
  return (
    <Container
      className='d-flex align-items-center justify-content-center'
      style={{ minHeight: '80vh' }}
    >
      <div className='w-100' style={{ maxWidth: '500px' }}>
        {children}
      </div>
    </Container>
  )
}

export default FormContainer
