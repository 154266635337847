import React, { useEffect, useState, useContext, useRef } from 'react'
import { LocationContext } from '../LocationAuthProvider'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Image,
} from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import axios from 'axios'
import { DP_SIZE, IMG_SIZE, MAX_BIO_SIZE } from '../constants/Sizes'
import { SIZE_VALIDATION } from '../constants/ToastTexts'
import DangerToast from '../components/toasts/DangerToast'
import SuccessToast from '../components/toasts/SuccessToast'
const EditProfileScreen = ({ location, history }) => {
  const context = useContext(LocationContext)
  const { user, authLoader, setuser } = context
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const fileRef = useRef(null)
  const [bio, setBio] = useState('')
  const [toast, setToast] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setName(user.name)
      setBio(user.bio)
      setImageUrl(user.image)
      // setImageUrl(user.image)
      // console.log(imageUrl)
    }
  }, [user, history])

  const handleUpload = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('images', image)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .post('/api/v1/upload', formData, config)
        .then(function (response) {
          updateProfile(response.data.files)
        })
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  const updateProfile = async (imageUrl) => {
    try {
      setLoading(true)
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.put(
        `/api/v1/user/profile`,
        { name, email, password, bio, imageUrl },
        config
      )

      if (data) {
        setuser(data)
        setVicToast('Update Successful')
        setVicShow(true)
        setImage(null)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setError('Passwords do not match')
    } else {
      if (image) {
        handleUpload()
      } else {
        updateProfile('')
      }
    }
  }
  const imageHandler = async (e) => {
    const file = e.target.files[0]

    file['relativePath'] = URL.createObjectURL(file)
    console.log(file)
    if (file.size < DP_SIZE) {
      setImage(file)
    } else {
      setShow(true)
      setToast(SIZE_VALIDATION)
    }
  }

  const removeFromLartHandler = () => {
    setImage(null)
  }

  const handleRemoveImage = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`/api/v1/user/profile`)

      setuser(data)
      setLoading(false)
      if (data) {
        setVicToast('Profile picture Removed')
        setVicShow(true)
      }
    } catch (error) {
      setLoading(false)
      setShow(true)
      setToast(error.response.data.message)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <div className='my-5'>
      {authLoader ? (
        <Loader />
      ) : user ? (
        <FormContainer>
          <h1>EDIT PROFILE </h1>
          {error && <Message variant='danger'> {error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label className='formlabel'>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label className='formlabel'>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label className='formlabel'>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                minLength={6}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='confrimpassword'>
              <Form.Label className='formlabel'>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setconfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload/Change Profile Picture</Form.Label>

              <Row>
                <Col>
                  {' '}
                  <Form.Control
                    id='image-file'
                    itemType='image'
                    type='file'
                    accept='image/*'
                    className='input-file'
                    ref={fileRef}
                    // style={{ color: 'transparent', width: '70px' }}
                    onChange={imageHandler}
                    onClick={() => {
                      fileRef.current.value = null
                    }}
                  />
                </Col>
                <Col>
                  {' '}
                  <Button
                    variant='danger'
                    disabled={!imageUrl}
                    onClick={handleRemoveImage}
                  >
                    Remove Profile Picture
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            {image ? (
              <ListGroup variant='flush' className='listcontainer'>
                <ListGroup.Item className='listgroup'>
                  <Row>
                    <Col md={3}>
                      <Image
                        src={image.relativePath}
                        alt={image.name}
                        fluid
                        rounded
                        height='50'
                        width='50'
                      />
                    </Col>
                    <Col className='imagename' md={4}>
                      {image.name}
                    </Col>
                    <Col className='deletecontainer'>
                      <Button
                        onClick={() => {
                          removeFromLartHandler()
                        }}
                        type='button'
                        variant='light'
                      >
                        <i className='fas fa-trash fa-lg'></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            ) : null}
            <Form.Group controlId='bio'>
              <Form.Label className='formlabel'>Bio</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={bio}
                maxLength={MAX_BIO_SIZE}
                onChange={(e) => setBio(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary' className='post-button'>
              Confrim Change
            </Button>
          </Form>
        </FormContainer>
      ) : null}

      <DangerToast show={show} setShow={setShow} text={toast} />
      <SuccessToast vicshow={vicshow} setVicShow={setVicShow} text={victoast} />
    </div>
  )
}

export default EditProfileScreen
