import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import defaultMush from '../../avatar/defaultmush.png'
import { API_URL } from '../../constants/Datas'

const MushroomCard = ({ onClick, post, postref }) => {
  var imgs = post.files.split(',')
  return (
    <Card
      className='card-main'
      ref={postref}
      style={{ borderWidth: '0px', borderColor: 'black' }}
    >
      <Link className='text-link' to={`/place/${post._id}`}>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Card.Img
            className='cardimg'
            // height={240}

            variant='top'
            src={imgs[0] ? API_URL + imgs[0] : defaultMush}
            alt='Mushroom Image'
          />{' '}
        </div>
      </Link>
      <Link className='text-link' to={`/place/${post._id}`}>
        <Card.Body>
          <Card.Title>{post.title}</Card.Title>
          <Card.Text className='block-with-text'>{post.details}</Card.Text>
        </Card.Body>
      </Link>
    </Card>
  )
}

export default MushroomCard
