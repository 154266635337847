import React, { useState, useContext } from 'react'
import GoogleMapReact from 'google-map-react'

import { LocationContext } from '../LocationAuthProvider'
import LocationMarker from './LocationMarker'
import { Button } from 'react-bootstrap'
import Marker from './Marker'
const { REACT_APP_GOOGLE_API_KEY } = process.env

const Map = (props) => {
  const context = useContext(LocationContext)
  const [idChange, setidChange] = useState(null)
  const [currentLocationMarker, setcurrentLocationMarker] = useState(null)
  const {
    coordinates,
    postMarker,
    refs,
    privateRefs,
    setCoordinates,
    user,
    dropDownText,
    privatePosts,
    setprivatePosts,
  } = context
  const zoom = 10

  const handleClick = (id) => {
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    if (idChange) {
      refs[idChange].current.style.borderWidth = '0px'
      setidChange(id)
    } else {
      setidChange(id)
    }

    refs[id].current.style.borderWidth = '2px'
  }
  const handleClickPrivate = (id) => {
    privateRefs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    if (idChange) {
      privateRefs[idChange].current.style.borderWidth = '0px'
      setidChange(id)
    } else {
      setidChange(id)
    }

    privateRefs[id].current.style.borderWidth = '2px'
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.')
        break
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.')
        break
      case error.TIMEOUT:
        alert('The request to get user location timed out.')
        break
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.')
        break
    }
  }

  const handleLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          formattedAddress: '',
        })

        setcurrentLocationMarker({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
        const data = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        localStorage.setItem('currentlocation', JSON.stringify(data))
      },
      showError,
      options
    )
  }
  return (
    <div className='map'>
      <button
        className='current-location'
        onClick={handleLocation}
        disabled={!user}
      >
        <i className='fas fa-compass fa-2x'></i>
      </button>
      <GoogleMapReact
        bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY }}
        center={coordinates}
        zoom={zoom}
      >
        {dropDownText === 'All Post'
          ? postMarker.map((post) => (
              <LocationMarker
                key={post._id}
                lat={post.location.coordinates[1]}
                lng={post.location.coordinates[0]}
                type={post.mushroomType}
                onClick={() => {
                  handleClick(post._id)
                }}
              />
            ))
          : privatePosts.map((post) => (
              <LocationMarker
                key={post._id}
                lat={post.location.coordinates[1]}
                lng={post.location.coordinates[0]}
                type={post.mushroomType}
                onClick={() => {
                  handleClickPrivate(post._id)
                }}
              />
            ))}

        {currentLocationMarker && (
          <Marker
            lat={currentLocationMarker.lat}
            lng={currentLocationMarker.lng}
          />
        )}
      </GoogleMapReact>
    </div>
  )
}

export default Map
