import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LocationContext } from '../src/LocationAuthProvider'
import Loader from './components/Loader'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const context = useContext(LocationContext)

  const { user, authLoader } = context
  return (
    <Route
      {...rest}
      render={(props) => {
        return user == false ? (
          <Redirect to='/login' />
        ) : (
          <Component {...props} />
        )
      }}
    ></Route>
  )
}

export default PrivateRoute
