import React from 'react'
import { Modal } from 'react-bootstrap'
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from 'react-share'
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from 'react-share'
import Clipboard from 'react-clipboard.js'

const ShareModal = (props) => {
  let { url } = props
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} className='mx-2' />
        </FacebookShareButton>

        <TwitterShareButton url={url}>
          <TwitterIcon size={32} className='mx-2' />
        </TwitterShareButton>

        <EmailShareButton url={url}>
          <EmailIcon size={32} className='mx-2' />
        </EmailShareButton>
        <PinterestShareButton url={url} media={url}>
          <PinterestIcon size={32} className='mx-2' />
        </PinterestShareButton>

        <RedditShareButton url={url}>
          <RedditIcon size={32} className='mx-2' />
        </RedditShareButton>

        <WhatsappShareButton url={url}>
          <WhatsappIcon size={32} className='mx-2' />
        </WhatsappShareButton>

        <Clipboard
          data-clipboard-text={url}
          className='mx-1'
          style={{ backgroundColor: 'white', borderWidth: '1px' }}
        >
          <i className='fas fa-link'></i>{' '}
        </Clipboard>
      </Modal.Body>
    </Modal>
  )
}

export default ShareModal
