import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
} from '../components/utils/Validation'
import axios from 'axios'
import { LocationContext } from '../LocationAuthProvider'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../constants/Datas'
const RegisterScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const context = useContext(LocationContext)
  const [checked, setChecked] = useState(false)
  const { user, setuser } = context
  //   const dispatch = useDispatch()
  //   const userLogin = useSelector((state) => state.user)

  const redirect = location.search ? location.search.split('=')[1] : '/'

  const handleCheckbox = () => {
    setChecked()
  }

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [history, user, redirect])

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const submitHandler = async (e) => {
    e.preventDefault()

    if (isEmpty(name) || isEmpty(password) || isEmpty(email))
      return setError('Please fill in all fields.')

    if (!isEmail(email)) return setError('Invalid email format.')
    if (isLength(password))
      return setError('Password must be at least 6 characters.')

    if (!isMatch(password, confirmPassword)) {
      return setError('Password confirmation did not match.')
    }

    try {
      setLoading(true)
      const { data } = await axios.post(
        '/api/v1/user/register',
        { name, email, password },
        config
      )
      setuser(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <FormContainer className='reg-re'>
      <div className='reg-re'> </div>

      <h1>SIGN UP </h1>
      {error && <Message variant='danger'> {error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name'>
          <Form.Label className='formlabel'>Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='email'>
          <Form.Label className='formlabel'>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='password'>
          <Form.Label className='formlabel'>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            minLength={6}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='confirmpassword'>
          <Form.Label className='formlabel'>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirm password'
            value={confirmPassword}
            onChange={(e) => setconfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <div className='privpolicy'>
          <Form.Group className='my-3' controlId='formBasicCheckbox'>
            <Form.Check
              type='checkbox'
              checked={checked}
              onChange={(e) => setChecked(e.currentTarget.checked)}
            />
          </Form.Group>
          <div className='text-button py-3 mx-2'>
            I Agree to the{' '}
            <Link
              className='text-link px-1'
              to={{ pathname: TERMS_OF_USE_URL }}
              target='_blank'
            >
              <div className='Text'> Terms of Use </div>
            </Link>
            and{' '}
            <Link
              className='text-link'
              to={{ pathname: PRIVACY_POLICY_URL }}
              target='_blank'
            >
              <div className='Text'> Privacy Policy</div>
            </Link>
          </div>
        </div>

        <Button
          type='submit'
          variant='primary'
          className='button'
          disabled={!checked}
        >
          SIGN UP
        </Button>
      </Form>

      <div className='text-button py-3'>
        Already Have an Account ?{' '}
        <Link className='text-link' to={'/login'}>
          <div className='Text'> Login </div>
        </Link>
      </div>
    </FormContainer>
  )
}

export default RegisterScreen
