import React from 'react'
import { Card } from 'react-bootstrap'
const MushroomType = (props) => {
  const { type } = props
  return (
    <Card
      //   className='my-1'
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        padding: 10,
        justifyContent: 'center',
      }}
    >
      {type}
    </Card>
  )
}

export default MushroomType
