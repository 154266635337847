import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
} from '../components/utils/Validation'
import axios from 'axios'
import { LocationContext } from '../LocationAuthProvider'

const ForgotPassEmailScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [sentEmail, setsentEmail] = useState('')
  const context = useContext(LocationContext)
  const { user, setuser } = context
  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [history, user, redirect])
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const submitHandler = async (e) => {
    e.preventDefault()

    if (isEmpty(email)) return setError('Please fill in all fields.')

    try {
      setLoading(true)
      const { data } = await axios.post(
        '/api/v1/user/forgotpassword',
        { email },
        config
      )
      if (data) {
        setError('')
        setEmail('')
        setsentEmail(data.message)
        console.log(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setsentEmail('')

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  return (
    <FormContainer>
      {loading && <Loader />}
      <h3 className='py-2'>Forgot Password?</h3>
      {sentEmail && <Message variant='success'> {sentEmail}</Message>}
      {error && <Message variant='danger'> {error}</Message>}
      Enter the email address you used when you joined and we’ll send you
      instructions to reset your password.
      <Form className='py-2' onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label className='formlabel'>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter Email'
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary' className='button'>
          Continue{' '}
        </Button>
      </Form>
    </FormContainer>
  )
}

export default ForgotPassEmailScreen
