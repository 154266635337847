import React, { useEffect, useState, useContext } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Container, Modal, Form } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import AuthLoader from '../components/AuthLoader'
import { LocationContext } from '../LocationAuthProvider'
import axios from 'axios'
import SideBar from '../components/SideBar'

const UserListScreen = ({ history }) => {
  const context = useContext(LocationContext)
  const { user, authLoader } = context
  const [modalShow, setModalShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, seterror] = useState(null)
  const [users, setUser] = useState([])
  const [checked, setChecked] = useState(false)
  const [loadingUpdate, setloadingUpdate] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState('')
  const getUserList = async () => {
    try {
      setLoading(true)
      await axios.get('/api/v1/admin/userlist').then(function (response) {
        setUser(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      seterror(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  useEffect(() => {
    getUserList()
  }, [history, user, loadingUpdate])

  function EditModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <Image
            className='englargedmoal'
            src={enlargedImg}
            alt='Enlarged Mushroom Image'
          /> */}

          <div className='privpolicy'>
            <Form.Group className='my-3' controlId='formBasicCheckbox'>
              <Form.Check
                type='checkbox'
                checked={checked}
                onChange={(e) => setChecked(e.currentTarget.checked)}
              />
            </Form.Group>
            <div className='text-button mx-2'>I Agree to the</div>
          </div>
          <Button
            variant='primary'
            type='submit'
            className='post-button'
            disabled={!user}
            onClick={() => {
              // submitHandle()
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    )
  }

  const deleteUser = async (id) => {
    try {
      setloadingUpdate(true)
      await axios.delete(`/api/v1/admin/handleuser/${id}`)

      setloadingUpdate(false)
    } catch (error) {
      setloadingUpdate(false)
      setErrorUpdate(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(id)
    }
  }
  return (
    <Container>
      <h1 className='my-3'>Users</h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>ADMIN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.name}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>
                  {user.isAdmin ? (
                    <i className='fas fa-check' style={{ color: 'green' }}></i>
                  ) : (
                    <i className='fas fa-times' style={{ color: 'red' }}></i>
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/user/${user._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>

                  <Button
                    variant='danger'
                    className='btn-sm'
                    onClick={() => deleteHandler(user._id)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <EditModal show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  )
}

export default UserListScreen
