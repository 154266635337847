import React, { useEffect, useState, useContext, useRef } from 'react'
import Message from '../components/Message'
import Loader from '../components/Loader'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { LocationContext } from '../LocationAuthProvider'

const BlogContentScreen = ({ match, history }) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [blogs, setblogs] = useState([])
  const context = useContext(LocationContext)

  const { user } = context

  const getblog = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `/api/v1/admin/getblog/${match.params.id}`
      )
      setblogs(data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  const deleteblog = async () => {
    setLoading(true)

    try {
      await axios
        .delete(`/api/v1/admin/getblog/${match.params.id}`)
        .then(function (response) {
          setLoading(false)
          history.push('/blogs')
        })
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const deletehandler = () => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      deleteblog()
    }
  }

  useEffect(() => {
    getblog()
  }, [match, history])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <div className='postPage' style={{ width: '60%', margin: '3rem auto' }}>
          <Message variant='danger'>{error}</Message>
        </div>
      ) : (
        <div className='postPage' style={{ width: '60%', margin: '3rem auto' }}>
          {user && user.isAdmin && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '20px',
              }}
            >
              {/* <Link to={`/editblog/`}>
                <Button type='button' variant='light'>
                  <i className='fas fa-edit  fa-lg'></i>
                </Button>
              </Link> */}
              <Button type='button' variant='light' onClick={deletehandler}>
                <i className='fas fa-trash fa-lg'></i>
              </Button>
            </div>
          )}

          <div dangerouslySetInnerHTML={{ __html: blogs.content }} />
        </div>
      )}
    </div>
  )
}

export default BlogContentScreen
