import React, {
  useState,
  useEffect,
  useContext,
  createRef,
  useRef,
} from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  DropdownButton,
  Form,
  Dropdown,
  Accordion,
} from 'react-bootstrap'

import MushroomCard from '../components/cards/MushroomCard'
import Footer from '../components/Footer'
import Map from '../components/Map'
import axios from 'axios'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { LocationContext } from '../LocationAuthProvider'
import { useMediaQuery } from 'react-responsive'
import Ad from '../components/Ad'

const HomeScreen = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [pastMonths, setpastMonths] = useState([])
  const context = useContext(LocationContext)
  const {
    postMarker,
    setpostMarker,
    refs,
    user,
    privatePosts,
    setprivatePosts,
    dropDownText,
    setdropDownText,
    privateRefs,
  } = context
  const postRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [show, setShow] = useState(isTabletOrMobile)
  const [isListView, setisListView] = useState(isTabletOrMobile)
  // const [privatePosts, setprivatePosts] = useState([])
  // const [dropDownText, setdropDownText] = useState('All Post')
  const fetchPrivateEvents = async () => {
    try {
      setLoading(true)
      await axios.get('api/v1/places/privatepost').then(function (response) {
        setprivatePosts(response.data.data)
      })
      setLoading(false)
    } catch (error) {
      history.push('/login')
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  const fetchEvents = async () => {
    try {
      setLoading(true)
      await axios.get('api/v1/places').then(function (response) {
        setpostMarker(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  var monthNames = [
    {
      name: 'January',
      num: 1,
    },
    { name: 'February', num: 2 },
    { name: 'March', num: 3 },
    { name: 'April', num: 4 },
    { name: 'May', num: 5 },
    { name: 'June', num: 6 },
    { name: 'July', num: 7 },
    { name: 'August', num: 8 },
    { name: 'Spetember', num: 9 },
    { name: 'October', num: 10 },
    { name: 'November', num: 11 },
    { name: 'December', num: 12 },
  ]
  // const handleScroll = (id) => {
  //   postRef.current.scrollIntoView({ behavior: 'smooth' })
  // }
  const [filters, setFilters] = useState([])
  const getPast6months = () => {
    var monthNames = [
      {
        name: 'January',
        num: 1,
      },
      { name: 'February', num: 2 },
      { name: 'March', num: 3 },
      { name: 'April', num: 4 },
      { name: 'May', num: 5 },
      { name: 'June', num: 6 },
      { name: 'July', num: 7 },
      { name: 'August', num: 8 },
      { name: 'September', num: 9 },
      { name: 'October', num: 10 },
      { name: 'November', num: 11 },
      { name: 'December', num: 12 },
    ]

    var today = new Date()
    var d
    var month
    var year
    let months = []
    for (var i = 6; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1)
      month = monthNames[d.getMonth()]
      year = d.getFullYear()

      months.push({ name: month, year: year })
    }
    setpastMonths(months.reverse())
  }

  const handleAlertClick = () => {
    if (isListView) {
      setisListView(false)
    } else {
      setisListView(true)
    }
  }

  useEffect(() => {
    fetchEvents()
    getPast6months()
  }, [])

  useEffect(() => {
    fetchPrivateEvents()
  }, [])

  const getFilteredData = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      await axios
        .put(
          `/api/v1/places/filter/month`,
          {
            filters,
          },
          config
        )
        .then(function (response) {
          setpostMarker(response.data.data)
        })
    } catch (error) {}
  }
  const handleChange = (e) => {
    const { name, checked } = e.target
    if (checked) {
      if (!filters.includes(name)) {
        let arr = filters
        arr.push(parseInt(name))
        setFilters(arr)
        getFilteredData()
      }
    } else {
      let ind = filters.indexOf(
        filters.filter(function (item) {
          return item == name
        })[0]
      )
      filters.splice(ind, 1) //deleting
      setFilters(filters)

      getFilteredData()
    }

    let tempUser = pastMonths.map((user) =>
      user.name.num == name ? { ...user, isChecked: checked } : user
    )
    setpastMonths(tempUser)
  }
  return (
    <div className='main'>
      {isTabletOrMobile ? (
        <Row>
          {isListView ? (
            <Col className='container-right-mobile'>
              {loading ? (
                <Loader />
              ) : errorMessage ? (
                <Message variant='danger'>{errorMessage}</Message>
              ) : (
                <Row className='g-4 mobile-margin'>
                  {/* <Ad /> */}

                  <DropdownButton
                    id='dropdown-item-button'
                    title={dropDownText}
                    variant='primary'
                  >
                    <Dropdown.Item
                      as='button'
                      onClick={() => {
                        setdropDownText('All Post')
                      }}
                    >
                      All Post
                    </Dropdown.Item>
                    <Dropdown.Item
                      as='button'
                      disabled={!user}
                      onClick={() => {
                        setdropDownText('Private Post')
                      }}
                    >
                      Private Post
                    </Dropdown.Item>
                  </DropdownButton>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Filter by Month</Accordion.Header>
                      <Accordion.Body>
                        {pastMonths.map((type) => (
                          <div
                            key={`default-${type.name.name}`}
                            className='mb-3'
                          >
                            <Form.Check
                              type='checkbox'
                              id={`${type.name.name}`}
                              name={`${type.name.num}`}
                              label={` ${type.name.name}-${type.year}`}
                              onChange={handleChange}
                              checked={type?.isChecked || false}
                            />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {dropDownText === 'All Post' ? (
                    postMarker.length == 0 ? (
                      <Container className='footer-position'>
                        <h1>No Post Available</h1>
                      </Container>
                    ) : (
                      postMarker.map((post) => (
                        <Col xs={12} xl={12} xxl={6}>
                          {/* <Button onClick={() => handleClick(post._id)} /> */}

                          <MushroomCard
                            key={post._id}
                            post={post}
                            postref={refs[post._id]}
                          />
                        </Col>
                      ))
                    )
                  ) : privatePosts.length == 0 ? (
                    <Container className='footer-position'>
                      <h1 className='my-5'> You have no private Post </h1>
                    </Container>
                  ) : (
                    privatePosts.map((post) => (
                      <Col xs={12} xl={12} xxl={6}>
                        {/* <Button onClick={() => handleClick(post._id)} /> */}

                        <MushroomCard
                          key={post._id}
                          post={post}
                          postref={privateRefs[post._id]}
                        />
                      </Col>
                    ))
                  )}
                </Row>
              )}
              {isTabletOrMobile ? (
                <div className='alert-mid'>
                  <Alert
                    variant='dark'
                    onClose={() => setShow(false)}
                    // show={show}
                    className='alert-mobile'
                    onClick={handleAlertClick}
                  >
                    {isListView ? 'Map View' : 'List View'}
                  </Alert>
                </div>
              ) : null}
              <div className='footer-position'>
                <Footer />
              </div>
            </Col>
          ) : (
            <Col xs={6} md={8} className='map-container-mobile'>
              <Map />
              {isTabletOrMobile ? (
                <div className='alert-mid'>
                  <Alert
                    variant='dark'
                    onClose={() => setShow(false)}
                    // show={show}
                    className='alert-mobile'
                    onClick={handleAlertClick}
                  >
                    {isListView ? 'Map View' : 'List View'}
                  </Alert>
                </div>
              ) : null}
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col xs={6} md={8} className='map-container'>
            <Map />
          </Col>
          <Col className='container-right-mobile'>
            {loading ? (
              <Loader />
            ) : errorMessage ? (
              <Message variant='danger'>{errorMessage}</Message>
            ) : (
              <Row className='g-4'>
                {/* <Ad /> */}

                <DropdownButton
                  id='dropdown-item-button'
                  title={dropDownText}
                  variant='primary'
                >
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setdropDownText('All Post')
                    }}
                  >
                    All Post
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    disabled={!user}
                    onClick={() => {
                      setdropDownText('Private Post')
                    }}
                  >
                    Private Post
                  </Dropdown.Item>
                </DropdownButton>
                <Accordion defaultActiveKey='1'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Filter by Month</Accordion.Header>
                    <Accordion.Body>
                      {pastMonths.map((type) => (
                        <div key={`default-${type.name.name}`} className='mb-3'>
                          <Form.Check
                            type='checkbox'
                            id={`${type.name.name}`}
                            name={`${type.name.num}`}
                            label={` ${type.name.name}-${type.year}`}
                            onChange={handleChange}
                            checked={type?.isChecked || false}
                          />
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {dropDownText === 'All Post' ? (
                  postMarker.length == 0 ? (
                    <Container className='footer-position'>
                      <h1>No Post Available</h1>
                    </Container>
                  ) : (
                    postMarker.map((post) => (
                      <Col xs={12} xl={12} xxl={6}>
                        {/* <Button onClick={() => handleClick(post._id)} /> */}

                        <MushroomCard
                          key={post._id}
                          post={post}
                          postref={refs[post._id]}
                        />
                      </Col>
                    ))
                  )
                ) : privatePosts.length == 0 ? (
                  <Container className='footer-position'>
                    <h1 className='my-5'> You have no private Post </h1>
                  </Container>
                ) : (
                  privatePosts.map((post) => (
                    <Col xs={12} xl={12} xxl={6}>
                      {/* <Button onClick={() => handleClick(post._id)} /> */}

                      <MushroomCard
                        key={post._id}
                        post={post}
                        postref={privateRefs[post._id]}
                      />
                    </Col>
                  ))
                )}

                {isTabletOrMobile ? (
                  <div className='alert-mid'>
                    <Alert
                      variant='dark'
                      onClose={() => setShow(false)}
                      // show={show}
                      className='alert-mobile'
                      onClick={handleAlertClick}
                    >
                      {isListView ? 'List View' : 'Map View'}
                    </Alert>
                  </div>
                ) : null}
              </Row>
            )}
            <div className='footer-position'>
              <Footer />
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default HomeScreen
