import React, { useEffect, useState, useRef } from 'react'

import QuillEditor from '../components/QuillEditor'
import axios from 'axios'
import { Button, Form } from 'react-bootstrap'
import MultiSelect from '../components/MultiSelect'
import { BLOG_OPTION } from '../constants/Datas'
import Loader from '../components/Loader'
import Message from '../components/Message'

function CreateBlogPostScreen(props) {
  const [content, setContent] = useState('')
  const [files, setFiles] = useState([])
  const [blogType, SetBlogType] = useState([])
  const [loading, setloading] = useState(false)
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const fileRef = useRef(null)
  const [images, setImages] = useState(null)
  const [details, setDetails] = useState('')

  const onEditorChange = (value) => {
    setContent(value)
    console.log(content)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setloading(true)

    try {
      if (
        blogType.length > 0 &&
        content.length > 0 &&
        title &&
        images &&
        details
      ) {
        const formData = new FormData()
        formData.append('images', images)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        await axios
          .post('/api/v1/upload', formData, config)
          .then(function (response) {
            const variables = {
              content: content,
              blogType: blogType,
              title: title,
              cover: response.data.files,
              desc: details,
            }

            axios
              .post('/api/v1/admin/createblogpost', variables)
              .then((response) => {
                if (response) {
                  props.history.push('/blogs')
                  setloading(false)

                  setContent('')
                }
                setloading(false)
              })
          })
      } else {
        setloading(false)
        alert('Blog Cannot be empty or You may have forgot to add blog type')
      }
    } catch (error) {
      setloading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  const imageHandler = (e) => {
    const newImage = e.target.files[0]
    setImages(newImage)
  }
  return (
    <div style={{ maxWidth: '900px', margin: '2rem auto' }}>
      {error && <Message variant='danger'>{error}</Message>}
      <div className='my-3' style={{ textAlign: 'center', width: '300px' }}>
        <MultiSelect SetmushroomType={SetBlogType} Options={BLOG_OPTION} />
      </div>
      <Form.Group controlId='title'>
        <Form.Control
          className='my-3'
          required
          type='text'
          placeholder='Blog Title'
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Group>

      <QuillEditor
        placeholder={'Start Posting Something'}
        onEditorChange={onEditorChange}
        onFilesChange={onFilesChange}
      />
      <Form.Group>
        <Form.Control
          id='image-file'
          itemType='image'
          type='file'
          placeholder='select Image'
          accept='image/*'
          // className='input-file'
          className='my-3'
          ref={fileRef}
          // style={{ color: 'transparent', width: '70px' }}
          onChange={imageHandler}
          onClick={() => {
            fileRef.current.value = null
          }}
        />
      </Form.Group>
      <div className='form-group'>
        <textarea
          className='form-control'
          id='details'
          rows='7'
          placeholder='Add first para of the blog ...'
          value={details}
          aria-label='Place Details'
          onChange={(e) => setDetails(e.target.value)}
        ></textarea>
      </div>
      <Form onSubmit={onSubmit}>
        <div style={{ marginTop: '1.5rem' }}>
          <Button
            size='large'
            type='submit'
            variant='primary'
            className='button'
            // onSubmit={onSubmit}
          >
            Publish
          </Button>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {loading && <Loader />}
          </div>
        </div>
      </Form>
    </div>
  )
}

export default CreateBlogPostScreen
