import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import {
  Container,
  Carousel,
  Image,
  ListGroup,
  Row,
  Col,
  Modal,
  Button,
  Spinner,
  DropdownButton,
  Dropdown,
  Form,
  ListGroupItem,
} from 'react-bootstrap'
import defaultMush from '../avatar/defaultmush.png'
import Loader from '../components/Loader'
import Message from '../components/Message'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { LocationContext } from '../LocationAuthProvider'
import MushroomType from '../components/cards/MushroomType'
import ShareModal from '../modals/ShareModal'
import Avatar from 'react-avatar'
import { API_URL, AVATAR_URL } from '../constants/Datas'
import SuccessToast from '../components/toasts/SuccessToast'
import { MAX_COMMENT_SIZE } from '../constants/Sizes'
import AuthLoader from '../components/AuthLoader'

const PlaceDetails = ({ history, match }) => {
  const context = useContext(LocationContext)
  const [modalShow, setModalShow] = useState(false)
  const [sharemodalShow, setShareModalShow] = useState(false)
  const [sharelink, setsharelink] = useState('')
  const [loading, setLoading] = useState(false)
  const [place, setPlace] = useState({ data: '' })
  const [errorMessage, seterrorMessage] = useState(null)
  const [address, setAddress] = useState('')
  const [mushrooms, setmushrooms] = useState([])
  const [fav, setfav] = useState(false)
  const [mushroomPost, setMushroomPost] = useState({})
  const [userload, setuserLoad] = useState(false)
  const [postCreator, setpostCreator] = useState(null)
  const [userID, setUserID] = useState('')
  const [commentLoader, setCommentLoader] = useState(false)
  const [reacted, setReacted] = useState(false)
  const [numofLikes, setnumofLikes] = useState('0')
  const [latlng, setLatLng] = useState({
    lat: 47.751076,
    lng: -120.740135,
  })
  const [postComments, setPostComments] = useState([])
  const [enlargedImg, setenlargedImg] = useState('')
  const { setCoordinates, user, authLoader } = context
  const [comment, setComment] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  //   var imgs = post.files.split(',')
  //   .then(function (response) {
  //     setPlace({ data: response.data.data })
  //     var imgs = response.data.data.files.split(',')
  //     setimgs(imgs)
  //     setLoading(false)
  //   })
  let url = ''
  const [userLiked, setuserLiked] = useState([])
  const deletePlaceHandler = async () => {
    setLoading(true)

    try {
      await axios.delete(`/api/v1/places/${match.params.id}`)
      setLoading(false)

      history.push('/')
    } catch (error) {
      setLoading(false)

      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const [imgs, setimgs] = useState([])
  const fetchPlace = async () => {
    try {
      {
        !commentLoader && setLoading(true)
      }
      await axios
        .get(`/api/v1/places/${match.params.id}`)
        .then(function (response) {
          setPlace({ data: response.data.data })
          var imgs = response.data.data.files.split(',')
          setimgs(imgs)
          setAddress(response.data.data.location.formattedAddress)
          setLatLng({
            lat: response.data.data.location.coordinates[1],
            lng: response.data.data.location.coordinates[0],
          })
          setmushrooms(response.data.data.mushroomType)
          getuserbyID(response.data.data.user)
          setUserID(response.data.data.user)
          setPostComments(response.data.data.comments)

          setLoading(false)
        })
    } catch (error) {
      setCommentLoader(false)

      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const getActualFav = async () => {
    setReacted(true)

    const { data } = await axios.get(
      `/api/v1/places/userlike/${match.params.id}`
    )

    setnumofLikes(data.numLikes)
    setuserLiked(data.likes)

    if (user) {
      if (
        data.likes.filter(
          (like) => like.user.toString() === user._id.toString()
        ).length > 0
      ) {
        setfav(true)
      } else {
        setfav(false)
      }
    }
    setReacted(false)
  }

  const getuserbyID = async (id) => {
    setuserLoad(true)
    const { data } = await axios.get(`/api/v1/user/getuser/${id}`)
    setpostCreator(data)
    setuserLoad(false)
  }
  const handleLike = async () => {
    await axios.put(`/api/v1/places/like/${match.params.id}`)

    if (fav) {
      setfav(false)
    } else {
      setfav(true)
    }
  }

  useEffect(() => {
    fetchPlace()
    setSuccess(false)
  }, [match, success])

  useEffect(() => {
    getActualFav()
    setReacted(false)
  }, [fav, match, history])
  const handleLinkClick = () => {
    setCoordinates({
      lat: latlng.lat,
      lng: latlng.lng,
      formattedAddress: address,
    })
  }
  function ImageModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image
            className='englargedmoal'
            src={enlargedImg}
            alt='Enlarged Mushroom Image'
          />
        </Modal.Body>
      </Modal>
    )
  }
  const submitCommentHandler = async (e) => {
    e.preventDefault()
    // setLoading(true)
    setCommentLoader(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post(
        `/api/v1/places/${match.params.id}/comments`,
        { comment },
        config
      )
      if (data) {
        setVicShow(true)
        setSuccess(true)
        setVicToast('Comment Added')
      }
      setSuccess(true)

      setComment('')
      setCommentLoader(false)
    } catch (error) {
      setCommentLoader(false)

      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const handleShare = () => {
    if (typeof window === 'object') {
      url = String(window.location)
    }
    setsharelink(url)
    setShareModalShow(true)
  }

  return (
    <div className='main-flow'>
      {loading ? (
        <Loader />
      ) : (
        <Container className='placedetails my-3 '>
          {errorMessage ? (
            <Message variant='danger'>{errorMessage}</Message>
          ) : (
            <div>
              <Carousel variant='dark' hover='pause'>
                {imgs.map((url) => (
                  <Carousel.Item
                    onClick={() => {
                      setenlargedImg(url ? API_URL + url : defaultMush)
                      setModalShow(true)
                    }}
                  >
                    <Image
                      src={url ? API_URL + url : defaultMush}
                      alt='Mushroom Image'
                      fluid
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <Row>
                {' '}
                <Col>
                  {' '}
                  <ListGroup variant='flush'>
                    {user ? (
                      <ListGroup.Item>
                        {userID == user._id ? (
                          <Row
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Col md={2}>
                              <Button
                                type='button'
                                variant='light'
                                onClick={handleShow}
                              >
                                <i className='fas fa-trash fa-lg'></i>Delete
                              </Button>
                            </Col>

                            <Col md={1}>
                              <Link to={`/editpost/${match.params.id}`}>
                                <Button type='button' variant='light'>
                                  <i className='fas fa-edit  fa-lg'></i>Edit
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        ) : null}
                      </ListGroup.Item>
                    ) : null}

                    <ListGroup.Item>
                      <Row>
                        <Col> {numofLikes.toString()} Likes</Col>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {place.data.numComments} Comments
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row className='py-1'>
                        {authLoader ? (
                          <AuthLoader />
                        ) : user ? (
                          <Col className='col-details' onClick={handleLike}>
                            {/* userLiked.filter(
                              (like) =>
                                like.user.toString() === user._id.toString()
                            ).length > 0 ?  */}
                            {userLiked.filter(
                              (like) =>
                                like.user.toString() === user._id.toString()
                            ).length > 0 || fav ? (
                              <i
                                className='fas fa-heart px-2 fa-lg'
                                style={{ color: 'red' }}
                              ></i>
                            ) : (
                              <i className='far fa-heart px-2 fa-lg'></i>
                            )}
                            Like
                          </Col>
                        ) : null}

                        <Col
                          className='col-details'
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <i className='far fa-comment px-2 fa-lg'></i>
                          Comment
                        </Col>

                        <Col
                          className='col-details'
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={handleShare}
                        >
                          <i className='far fa-share-square px-2 fa-lg'></i>
                          Share
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Location
                        </Col>

                        <Col className='col-details'>
                          <Link
                            className='location-text-link'
                            to={'/'}
                            onClick={handleLinkClick}
                          >
                            {address}
                          </Link>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Sighting Date</Col>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {moment(place.data.sightingDate).format('ll')}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Mushrooms
                        </Col>
                        <Col>
                          <Row>
                            {mushrooms.map((mush) => (
                              <Col md={6}>
                                <MushroomType type={mush.label} />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        {userload ? (
                          <Spinner animation='grow' role='status'>
                            <span className='visually-hidden mx-4'>
                              Loading...
                            </span>
                          </Spinner>
                        ) : postCreator ? (
                          <Col
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              // justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              src={
                                postCreator.image
                                  ? API_URL + postCreator.image
                                  : AVATAR_URL
                              }
                              size={50}
                              className='sb-avatar'
                              style={{ margin: 10 }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                              }}
                            >
                              Posted by
                              <Link
                                className='location-text-link'
                                to={`/profile/${postCreator._id}`} //change to user id
                              >
                                {postCreator.name}
                              </Link>
                            </div>
                          </Col>
                        ) : (
                          <Col>User Unavailable</Col>
                        )}

                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Last Updated <br />
                          {moment(place.data.updatedAt).format('ll')}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <h4>{place.data.title}</h4>
                        <Col className='detailstext my-1'>
                          {place.data.details}
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    {/* hh */}
                    <h4 className='mx-3'>Comments</h4>

                    {loading ? (
                      <Loader />
                    ) : postComments ? (
                      <div>
                        {postComments.length === 0 && (
                          <Message variant='info'>No Comments</Message>
                        )}
                        <ListGroup variant='flush'>
                          {loading && <Loader />}
                          {errorMessage && (
                            <Message variant='danger'>{errorMessage}</Message>
                          )}

                          {postComments.map((comment) => (
                            <ListGroup.Item key={comment._id}>
                              <Row>
                                <Col>
                                  <Link
                                    className='location-text-link'
                                    to={`/profile/${comment.user}`} //change to user id
                                  >
                                    {comment.name}
                                  </Link>
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  {moment(comment.createdAt).format('ll')}
                                </Col>
                              </Row>

                              <p className='comment'> {comment.comment}</p>
                            </ListGroup.Item>
                          ))}

                          <ListGroup.Item>
                            {commentLoader && <AuthLoader />}

                            {user ? (
                              <Form onSubmit={submitCommentHandler}>
                                <Form.Group controlId='comment'>
                                  <h4>Write a comment</h4>

                                  <Form.Control
                                    as='textarea'
                                    row='4'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                  ></Form.Control>
                                </Form.Group>
                                <Button
                                  className='my-3'
                                  disabled={authLoader}
                                  maxLength={MAX_COMMENT_SIZE}
                                  type='submit'
                                  variant='primary'
                                >
                                  Submit
                                </Button>
                              </Form>
                            ) : (
                              <Message variant='warning'>
                                Please{' '}
                                <Link to='/login' className='text-link mx-1'>
                                  Log in
                                </Link>{' '}
                                to write a comment{' '}
                              </Message>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    ) : null}

                    {/* hg */}
                  </ListGroup>
                </Col>
              </Row>

              <ImageModal show={modalShow} onHide={() => setModalShow(false)} />

              <ShareModal
                show={sharemodalShow}
                onHide={() => setShareModalShow(false)}
                url={sharelink}
              />

              <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your post will be deleted</Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant='primary' onClick={deletePlaceHandler}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Container>
      )}
      <SuccessToast vicshow={vicshow} setVicShow={setVicShow} text={victoast} />
    </div>
  )
}

export default PlaceDetails
