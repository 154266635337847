import React, { useState, useContext } from 'react'
import { Navbar, Nav, NavDropdown, Spinner, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { LocationContext } from '../LocationAuthProvider'
import PlaceAutocomplete from './PlaceAutocomplete'
import DangerToast from './toasts/DangerToast'
import logo from '../avatar/logofront.png'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { AMAZON_AFF_URL, ETSY_STORE_URL, OFFER_URL } from '../constants/Datas'
import Ad from './Ad'
const Header = ({ history }) => {
  const buttonVisible = true
  const context = useContext(LocationContext)
  const { user, authLoader, setuser } = context

  const logoutHandler = async () => {
    try {
      await axios.get(`/api/v1/user/logout`)
      document.location.href = '/login'
      setuser(null)
    } catch (error) {}
  }

  return (
    <header>
      <Link className='text-link' to={{ pathname: OFFER_URL }} target='_blank'>
        <div className='headerbanner'>
          LIMITED TIME. SAVE 50%+ (US ONLY).{' '}
          <Button className='header-banner-button' variant='outline-light'>
            View Offer
          </Button>{' '}
        </div>
      </Link>

      <Navbar variant='light' expand='lg' collapseOnSelect className='mx-4'>
        <LinkContainer to='/' exact>
          <Navbar.Brand>
            <img
              src={logo}
              width='200'
              height='40'
              className='d-inline-block align-top'
              alt='React Bootstrap logo'
            />
          </Navbar.Brand>
        </LinkContainer>
        <PlaceAutocomplete buttonVisible={buttonVisible} inputWidth={'20rem'} />

        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto'>
            <LinkContainer to='/' exact>
              <Nav.Link>
                <i className='fas fa-home'></i> Home
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/addsighting' exact>
              <Nav.Link>
                <i className='fas fa-plus-circle'></i> Add Sighting
              </Nav.Link>
            </LinkContainer>

            <Nav.Link href={ETSY_STORE_URL} target='_blank'>
              <i className='fas fa-shopping-cart'> </i> Shop
            </Nav.Link>

            <Nav.Link href={AMAZON_AFF_URL} target='_blank'>
              <i class='fas fa-handshake'></i> Affiliate
            </Nav.Link>
            <LinkContainer to='/aboutus' exact>
              <Nav.Link>
                <i className='fas fa-users'></i> About us
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/contactus' exact>
              <Nav.Link>
                <i className='fas fa-envelope'></i> Contact us
              </Nav.Link>
            </LinkContainer>

            {user && user.isAdmin && (
              <LinkContainer to='/blogs' exact>
                <Nav.Link>
                  <i className='fas fa-file-signature'></i> Blogs
                </Nav.Link>
              </LinkContainer>
            )}
            {user && user.isAdmin && (
              <NavDropdown title='Admin'>
                <LinkContainer to={`/admin/userlist`} exact>
                  <NavDropdown.Item>Dashboard</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/admin/createblogpost`} exact>
                  <NavDropdown.Item>Create Blog Post</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {authLoader ? (
              <Spinner animation='grow' role='status'>
                <span className='visually-hidden mx-4'>Loading...</span>
              </Spinner>
            ) : user ? (
              <NavDropdown title={user.name}>
                <LinkContainer to={`/profile/${user._id}`} exact>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Item onClick={logoutHandler}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <LinkContainer to='/login'>
                <Nav.Link>
                  <i className='fas fa-user'></i> Sign In
                </Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
